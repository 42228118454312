<template>
  <section class="col-7">
    <modal-loading :is-loading="loading" :message="loadingMessage" />
    <h2 class="mb-10">プロフィールの編集</h2>
    <v-alert v-if="editError" text color="error" class="mb-8">
      プロフィールの編集に失敗しました。
    </v-alert>
    <section>
      <div>
        <div>
          <v-text-field
            v-model="me.name"
            label="名前"
            type="text"
            :class="errorMessages.name ? 'mb-4' : ''"
            :error-messages="errorMessages.name"
            required
            outlined
          />
        </div>

        <div>
          <v-text-field
            v-model="me.email"
            label="メールアドレス"
            type="text"
            :class="errorMessages.email ? 'mb-4' : ''"
            :error-messages="errorMessages.email"
            required
            outlined
          />
        </div>

        <div class="mb-5">
          <div class="subtitle-1 mb-2">パスワード</div>
          <password
            v-model="me.password"
            placeholder=""
            default-class="Password__input"
            :secure-length="9"
            :required="false"
            :toggle="true"
            @feedback="passwordFeedback"
          />
          <v-alert v-if="showPasswordStrengthMessages" text color="error">
            <div
              v-for="(message, i) in passwordStrengthMessages"
              :key="i"
              class="py-1"
            >
              {{ message }}
            </div>
          </v-alert>
          <v-alert
            v-if="errorMessages.password"
            text
            color="error"
            class="mb-8"
          >
            {{ errorMessages.password }}
          </v-alert>
        </div>

        <div class="mb-10">
          <div class="subtitle-1 mb-2">パスワード確認</div>
          <password
            id="password_confirmation"
            v-model="me.password_confirmation"
            placeholder=""
            default-class="Password__input"
            :badge="false"
            :show-strength-meter="false"
            :required="false"
            :toggle="true"
          />
          <v-alert
            v-if="errorMessages.password_confirmation"
            text
            color="error"
            class="mb-8"
          >
            {{ errorMessages.password_confirmation }}
          </v-alert>
        </div>
      </div>

      <hr class="mt-8 mb-4" />

      <v-col>
        <v-row justify="end">
          <v-btn color="primary" large @click="edit"> 上書き保存 </v-btn>
        </v-row>
      </v-col>
    </section>
  </section>
</template>

<script>
import Password from "vue-password-strength-meter";
import * as EmailValidator from "email-validator";

import ModalLoading from "../../components/ModalLoading.vue";
import { getData, updateData } from "../../axios";

export default {
  name: "UsersEdit",

  components: {
    Password,
    ModalLoading,
  },

  data() {
    return {
      isModal: false,
      loading: false,
      loadingMessage: "",
      editError: false,
      me: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      errorMessages: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      japaneseItems: {
        name: "名前",
        email: "メールアドレス",
        password: "パスワード",
        password_confirmation: "パスワード確認",
      },
      passwordFeedbackMessages: [],
      passwordStrengthMessages: [],
      showPasswordStrengthMessages: false,
    };
  },

  computed: {
    isValid() {
      const errors = Object.keys(this.errorMessages).filter((key) => {
        return this.errorMessages[key].length !== 0;
      });
      return errors.length === 0 && !this.showPasswordStrengthMessages;
    },
  },

  created() {
    this.me = Object.assign({}, this.$store.getters["auth/me"]);
  },

  methods: {
    passwordFeedback({ suggestions, warning }) {
      this.passwordFeedbackMessages = [];
      if (suggestions.lenght !== 0) {
        suggestions.forEach((m) => {
          this.passwordFeedbackMessages.push(m);
        });
      }
      if (warning) {
        this.passwordFeedbackMessages.push(warning);
      }
    },
    edit() {
      this.editError = false;
      this.validate();
      if (this.isValid) {
        this.loading = true;
        this.loadingMessage = "更新中...";
        getData("users/uniqueEmail", {
          type: "profile",
          email: this.me.email,
        }).then((res) => {
          if (res.unique) {
            updateData("me", this.me)
              .then(() => {
                this.loading = false;
                this.loadingMessage = "";
                this.$store.dispatch("auth/setMe");
                this.$store.dispatch("snackbar/setSnackbar", {
                  message: "プロフィールを編集しました。",
                  color: "success",
                  timeout: 2000,
                });
                this.editError = false;
              })
              .catch((err) => {
                this.loading = false;
                this.loadingMessage = "";
                this.editError = true;
                console.log(err.response);
              });
          } else {
            this.loading = false;
            this.loadingMessage = "";
            this.errorMessages["email"] = "メールアドレスが重複しています。";
          }
        });
      } else {
        console.log(this.isValid);
      }
    },
    validate() {
      this.errorReset();
      if (!EmailValidator.validate(this.me.email)) {
        this.errorMessages.email = "メールアドレスが不正です。";
      }
      Object.keys(this.me).forEach((key) => {
        this.checkRequired(key, ["name", "email"]);
        this.checkMaxLength(key, 32, [
          "name",
          "password",
          "password_confirmation",
        ]);
        this.checkMaxLength(key, 127, ["email"]);
        this.checkMinLength(key, 9, ["password", "password_confirmation"]);
      });
      this.checkConfirmation();
      if (
        this.passwordFeedbackMessages.length !== 0 &&
        this.me.password.length !== 0
      ) {
        this.showPasswordStrengthMessages = true;
        this.passwordStrengthMessages = this.passwordFeedbackMessages;
      }
    },
    checkRequired(key, requiredList) {
      if (requiredList.indexOf(key) !== -1 && this.me[key].length === 0) {
        this.errorMessages[key] = `${this.japaneseItems[key]}は必須項目です。`;
      }
    },
    checkMaxLength(key, max, targets) {
      targets.forEach((item) => {
        if (key === item && this.me[key] && max < this.me[key].length) {
          this.errorMessages[
            key
          ] = `${this.japaneseItems[key]}は${max}文字以下で記入して下さい。`;
        }
      });
    },
    checkMinLength(key, min, targets) {
      targets.forEach((item) => {
        if (key === item && this.me[key] && min > this.me[key].length) {
          this.errorMessages[
            key
          ] = `${this.japaneseItems[key]}は${min}文字以上で記入して下さい。`;
        }
      });
    },
    checkConfirmation() {
      if (this.me.password !== this.me.password_confirmation) {
        this.errorMessages["password_confirmation"] =
          "パスワードが一致しません。";
      }
    },
    errorReset() {
      this.showPasswordStrengthMessages = false;
      this.passwordStrengthMessages = [];
      Object.keys(this.errorMessages).forEach((key) => {
        this.errorMessages[key] = "";
      });
    },
  },
};
</script>
